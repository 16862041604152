// @import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

// @tailwind base;
// @tailwind components;
// @tailwind utilities;


// $primary: purple;
// @import 'bootstrap/dist/css/bootstrap.min.css';
/* The following line can be included in a src/App.scss */
$theme-colors: (
  "primary": #0B4496,
  "secondary": #F7F7F7,
  "danger": #ff4136
);
@import '~bootstrap/scss/bootstrap';


// @import url('https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@100;200&family=Saira:wght@100;300;400&display=swap');
// @import url('./assets/fonts/AlfaSlabOne-Regular.ttf');

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One:wght@100;400&display=swap');

// https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@200..900&display=swap
body, html {
  font-family: 'Saira', sans-serif !important;
  font-weight: 300;
	background-color: #eee !important;
	// overflow: hidden;

	// In IE (Internet Explorer) and Edge hide scroll bars
	// -ms-overflow-style: none;
	// scrollbar-width: none;
}

::-webkit-scrollbar{
  display: none; // hide scroll bars
}